/* eslint-disable func-names */
import React, { useEffect } from 'react';

const MessengerChat = () => {
  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        xfbml: true,
        version: 'v11.0',
      });
    };
    (function (d, s, id) {
      let js = null;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
  return (
    <>
      <div id="fb-root" />
      <div className="fb-customerchat" id="110229320410753" />
    </>
  );
};

export default MessengerChat;
