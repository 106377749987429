/* eslint-disable unicorn/prefer-module */
const React = require('react');
const { default: MessengerChat } = require('./src/components/messanger-chat');

exports.onRouteUpdate = () => {
  // wisepops workaround: https://support.wisepops.com/en/articles/2269220-wisepops-on-single-page-applications
  // eslint-disable-next-line no-undef
  if (window !== undefined && window.wisops) {
    // eslint-disable-next-line no-undef
    window.wisepops('pageview');
  }
};

exports.wrapPageElement = ({ element }) => (
  <>
    {element}
    <MessengerChat />
  </>
);
